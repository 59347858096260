import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function PortfolioDesign() {
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [tooltipAlignment, setTooltipAlignment] = useState({ x: 'left', y: 'top' });

    const handleMouseMove = (e, container) => {
        const rect = container.getBoundingClientRect();
        const tooltipWidth = 300;
        const tooltipHeight = 60;

        let xPos = e.clientX - rect.left;
        let yPos = e.clientY - rect.top;

        let xAlign = 'left';
        let yAlign = 'top';

        if (xPos + tooltipWidth > rect.width) {
            xAlign = 'right';
            xPos = rect.width - tooltipWidth;
        }

        if (yPos + tooltipHeight > rect.height) {
            yAlign = 'bottom';
            yPos = rect.height - tooltipHeight;
        }

        setTooltipPosition({ x: xPos, y: yPos });
        setTooltipAlignment({ x: xAlign, y: yAlign });
    };


    return (
        <>

            <svg className="svg-distort">
                <defs>
                    <filter id="displacement-filter" className='svg_filter'>
                        <feTurbulence
                            type="fractalNoise"
                            baseFrequency="0.02 0.05"
                            numOctaves="5"
                            result="warp"
                        />
                        <feGaussianBlur
                            in="warp"
                            stdDeviation="8"
                            result="blurredWarp"
                        />
                        <feDisplacementMap
                            in="SourceGraphic"
                            in2="blurredWarp"
                            scale="150"
                            xChannelSelector="R"
                            yChannelSelector="G"
                        />
                    </filter>
                </defs>
            </svg>
            {/* <div className="grid grid-cols-2">
                <div className="col-span-2 lg:col-span-1 border border-black relative cursor-pointer group rounded-[40px] overflow-hidden port_img port_img1"
                    onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}>
                    <Link to={`/portfolio-slug/1`}>
                        <img
                            src="/image/portfoilo/img/port-img20.jpg"
                            alt="Error1"
                            className="h-full w-full object-cover img_hover_effect"
                        />
                        <img
                            src="/image/portfoilo/img/port-img20.jpg"
                            alt="Error1"
                            className="h-full w-full object-cover img_hover_effect"
                        />

                        <div
                            style={{
                                top: tooltipAlignment.y === 'top' ? tooltipPosition.y : 'auto',
                                bottom: tooltipAlignment.y === 'bottom' ? 0 : 'auto',
                                left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                            }}
                            className="absolute z-20 border border-black uppercase px-3 hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white text-sm rounded-lg shadow-lg"
                        >
                            Art
                        </div>
                        <div
                            style={{
                                top: tooltipAlignment.y === 'top' ? tooltipPosition.y + 40 : 'auto',
                                bottom: tooltipAlignment.y === 'bottom' ? 40 : 'auto',
                                left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                            }}
                            className="absolute custom_tool_tip z-20 text-xl font-semibold border border-black hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white rounded-lg shadow-lg"
                        >
                            Visual Concept
                        </div>


                    </Link>
                </div>
                <div className="col-span-2 lg:col-span-1">
                    <div className="grid sm:grid-cols-2">
                        <div className="col-span-1 border border-black relative cursor-pointer group rounded-[40px] overflow-hidden port_img"
                            onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}>
                            <Link to={`/portfolio-slug/2`}>
                                <img
                                    src="/image/portfoilo/img/port-list3-img2.jpg"
                                    alt="Error1"
                                    className="h-full w-full object-cover img_hover_effect"
                                />
                                <img
                                    src="/image/portfoilo/img/port-list3-img2.jpg"
                                    alt="Error1"
                                    className="h-full w-full object-cover img_hover_effect"
                                />
                                <div
                                    style={{
                                        top: tooltipAlignment.y === 'top' ? tooltipPosition.y : 'auto',
                                        bottom: tooltipAlignment.y === 'bottom' ? 0 : 'auto',
                                        left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                        right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                                    }}
                                    className="absolute z-20 border border-black uppercase px-3 hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white text-sm rounded-lg shadow-lg"
                                >
                                    Art
                                </div>
                                <div
                                    style={{
                                        top: tooltipAlignment.y === 'top' ? tooltipPosition.y + 40 : 'auto',
                                        bottom: tooltipAlignment.y === 'bottom' ? 40 : 'auto',
                                        left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                        right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                                    }}
                                    className="absolute custom_tool_tip z-20 text-xl font-semibold border border-black hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white rounded-lg shadow-lg"
                                >
                                    Visual Concept
                                </div>
                            </Link>
                        </div>
                        <div className="col-span-1 border border-black relative cursor-pointer group rounded-[40px] overflow-hidden port_img"
                            onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}>
                            <Link to={`/portfolio-slug/3`}>
                                <img
                                    src="/image/portfoilo/img/port-list3-img3.jpg"
                                    alt="Error1"
                                    className="h-full w-full object-cover img_hover_effect"
                                />
                                <img
                                    src="/image/portfoilo/img/port-list3-img3.jpg"
                                    alt="Error1"
                                    className="h-full w-full object-cover img_hover_effect"
                                />
                                <div
                                    style={{
                                        top: tooltipAlignment.y === 'top' ? tooltipPosition.y : 'auto',
                                        bottom: tooltipAlignment.y === 'bottom' ? 0 : 'auto',
                                        left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                        right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                                    }}
                                    className="absolute z-20 border border-black uppercase px-3 hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white text-sm rounded-lg shadow-lg"
                                >
                                    Art
                                </div>
                                <div
                                    style={{
                                        top: tooltipAlignment.y === 'top' ? tooltipPosition.y + 40 : 'auto',
                                        bottom: tooltipAlignment.y === 'bottom' ? 40 : 'auto',
                                        left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                        right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                                    }}
                                    className="absolute custom_tool_tip z-20 text-xl font-semibold border border-black hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white rounded-lg shadow-lg"
                                >
                                    Visual Concept
                                </div>
                            </Link>
                        </div>
                        <div className="col-span-1 border border-black relative cursor-pointer group rounded-[40px] overflow-hidden port_img"
                            onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}>
                            <Link to={`/portfolio-slug/4`}>
                                <img
                                    src="/image/portfoilo/img/port-list2-img2.jpg"
                                    alt="Error1"
                                    className="h-full w-full object-cover img_hover_effect"
                                />
                                <img
                                    src="/image/portfoilo/img/port-list2-img2.jpg"
                                    alt="Error1"
                                    className="h-full w-full object-cover img_hover_effect"
                                />
                                <div
                                    style={{
                                        top: tooltipAlignment.y === 'top' ? tooltipPosition.y : 'auto',
                                        bottom: tooltipAlignment.y === 'bottom' ? 0 : 'auto',
                                        left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                        right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                                    }}
                                    className="absolute z-20 border border-black uppercase px-3 hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white text-sm rounded-lg shadow-lg"
                                >
                                    Art
                                </div>
                                <div
                                    style={{
                                        top: tooltipAlignment.y === 'top' ? tooltipPosition.y + 40 : 'auto',
                                        bottom: tooltipAlignment.y === 'bottom' ? 40 : 'auto',
                                        left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                        right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                                    }}
                                    className="absolute custom_tool_tip z-20 text-xl font-semibold border border-black hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white rounded-lg shadow-lg"
                                >
                                    Visual Concept
                                </div>
                            </Link>
                        </div>
                        <div className="col-span-1 border border-black relative cursor-pointer group rounded-[40px] overflow-hidden port_img"
                            onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}>
                            <Link to={`/portfolio-slug/5`}>
                                <img
                                    src="/image/portfoilo/img/port-list3-img4.jpg"
                                    alt="Error1"
                                    className="h-full w-full object-cover img_hover_effect"
                                />
                                <img
                                    src="/image/portfoilo/img/port-list3-img4.jpg"
                                    alt="Error1"
                                    className="h-full w-full object-cover img_hover_effect"
                                />
                                <div
                                    style={{
                                        top: tooltipAlignment.y === 'top' ? tooltipPosition.y : 'auto',
                                        bottom: tooltipAlignment.y === 'bottom' ? 0 : 'auto',
                                        left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                        right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                                    }}
                                    className="absolute z-20 border border-black uppercase px-3 hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white text-sm rounded-lg shadow-lg"
                                >
                                    Art
                                </div>
                                <div
                                    style={{
                                        top: tooltipAlignment.y === 'top' ? tooltipPosition.y + 40 : 'auto',
                                        bottom: tooltipAlignment.y === 'bottom' ? 40 : 'auto',
                                        left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                        right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                                    }}
                                    className="absolute custom_tool_tip z-20 text-xl font-semibold border border-black hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white rounded-lg shadow-lg"
                                >
                                    Visual Concept
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-span-2 border border-black relative cursor-pointer group rounded-[40px] overflow-hidden port_img port_img5"
                    onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}>
                    <Link to={`/portfolio-slug/6`}>
                        <img
                            src="/image/portfoilo/img/port-gallery-img1.jpg"
                            alt="Error1"
                            className="h-full w-full object-cover img_hover_effect"
                        />
                        <img
                            src="/image/portfoilo/img/port-gallery-img1.jpg"
                            alt="Error1"
                            className="h-full w-full object-cover img_hover_effect"
                        />
                        <div
                            style={{
                                top: tooltipAlignment.y === 'top' ? tooltipPosition.y : 'auto',
                                bottom: tooltipAlignment.y === 'bottom' ? 0 : 'auto',
                                left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                            }}
                            className="absolute z-20 border border-black uppercase px-3 hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white text-sm rounded-lg shadow-lg"
                        >
                            Art
                        </div>
                        <div
                            style={{
                                top: tooltipAlignment.y === 'top' ? tooltipPosition.y + 40 : 'auto',
                                bottom: tooltipAlignment.y === 'bottom' ? 40 : 'auto',
                                left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                            }}
                            className="absolute custom_tool_tip z-20 text-xl font-semibold border border-black hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white rounded-lg shadow-lg"
                        >
                            Visual Concept
                        </div>
                    </Link>
                </div>
                <div className="col-span-2 lg:col-span-1 border border-black relative cursor-pointer group rounded-[40px] overflow-hidden port_img port_img6"
                    onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}>
                    <Link to={`/portfolio-slug/7`}>
                        <img
                            src="/image/portfoilo/img/port-list3-img6.jpg"
                            alt="Error1"
                            className="h-full w-full object-cover img_hover_effect"
                        />
                        <img
                            src="/image/portfoilo/img/port-list3-img6.jpg"
                            alt="Error1"
                            className="h-full w-full object-cover img_hover_effect"
                        />
                        <div
                            style={{
                                top: tooltipAlignment.y === 'top' ? tooltipPosition.y : 'auto',
                                bottom: tooltipAlignment.y === 'bottom' ? 0 : 'auto',
                                left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                            }}
                            className="absolute z-20 border border-black uppercase px-3 hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white text-sm rounded-lg shadow-lg"
                        >
                            Art
                        </div>
                        <div
                            style={{
                                top: tooltipAlignment.y === 'top' ? tooltipPosition.y + 40 : 'auto',
                                bottom: tooltipAlignment.y === 'bottom' ? 40 : 'auto',
                                left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                            }}
                            className="absolute custom_tool_tip z-20 text-xl font-semibold border border-black hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white rounded-lg shadow-lg"
                        >
                            Visual Concept
                        </div>
                    </Link>
                </div>
                <div className="col-span-2 lg:col-span-1 border border-black relative cursor-pointer group rounded-[40px] overflow-hidden port_img port_img7"
                    onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}>
                    <Link to={`/portfolio-slug/8`}>
                        <img
                            src="/image/portfoilo/img/port-list3-img7.jpg"
                            alt="Error1"
                            className="h-full w-full object-cover img_hover_effect"
                        />
                        <img
                            src="/image/portfoilo/img/port-list3-img7.jpg"
                            alt="Error1"
                            className="h-full w-full object-cover img_hover_effect"
                        />
                        <div
                            style={{
                                top: tooltipAlignment.y === 'top' ? tooltipPosition.y : 'auto',
                                bottom: tooltipAlignment.y === 'bottom' ? 0 : 'auto',
                                left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                            }}
                            className="absolute z-20 border border-black uppercase px-3 hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white text-sm rounded-lg shadow-lg"
                        >
                            Art
                        </div>
                        <div
                            style={{
                                top: tooltipAlignment.y === 'top' ? tooltipPosition.y + 40 : 'auto',
                                bottom: tooltipAlignment.y === 'bottom' ? 40 : 'auto',
                                left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                            }}
                            className="absolute custom_tool_tip z-20 text-xl font-semibold border border-black hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white rounded-lg shadow-lg"
                        >
                            Visual Concept
                        </div>
                    </Link>
                </div>
                <div className="col-span-2">
                    <div className='grid sm:grid-cols-2 lg:grid-cols-4'>
                        <div className="col-span-1 border border-black relative cursor-pointer group rounded-[40px] overflow-hidden port_img"
                            onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}>
                            <Link to={`/portfolio-slug/9`}>
                                <img
                                    src="/image/portfoilo/img/port-list3-img8.jpg"
                                    alt="Error1"
                                    className="h-full w-full object-cover img_hover_effect"
                                />
                                <img
                                    src="/image/portfoilo/img/port-list3-img8.jpg"
                                    alt="Error1"
                                    className="h-full w-full object-cover img_hover_effect"
                                />
                                <div
                                    style={{
                                        top: tooltipAlignment.y === 'top' ? tooltipPosition.y : 'auto',
                                        bottom: tooltipAlignment.y === 'bottom' ? 0 : 'auto',
                                        left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                        right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                                    }}
                                    className="absolute z-20 border border-black uppercase px-3 hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white text-sm rounded-lg shadow-lg"
                                >
                                    Art
                                </div>
                                <div
                                    style={{
                                        top: tooltipAlignment.y === 'top' ? tooltipPosition.y + 40 : 'auto',
                                        bottom: tooltipAlignment.y === 'bottom' ? 40 : 'auto',
                                        left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                        right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                                    }}
                                    className="absolute custom_tool_tip z-20 text-xl font-semibold border border-black hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white rounded-lg shadow-lg"
                                >
                                    Visual Concept
                                </div>
                            </Link>
                        </div>
                        <div className="col-span-1 border border-black relative cursor-pointer group rounded-[40px] overflow-hidden port_img"
                            onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}>
                            <Link to={`/portfolio-slug/10`}>
                                <img
                                    src="/image/portfoilo/img/port-list2-img3.jpg"
                                    alt="Error1"
                                    className="h-full w-full object-cover img_hover_effect"
                                />
                                <img
                                    src="/image/portfoilo/img/port-list2-img3.jpg"
                                    alt="Error1"
                                    className="h-full w-full object-cover img_hover_effect"
                                />
                                <div
                                    style={{
                                        top: tooltipAlignment.y === 'top' ? tooltipPosition.y : 'auto',
                                        bottom: tooltipAlignment.y === 'bottom' ? 0 : 'auto',
                                        left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                        right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                                    }}
                                    className="absolute z-20 border border-black uppercase px-3 hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white text-sm rounded-lg shadow-lg"
                                >
                                    Art
                                </div>
                                <div
                                    style={{
                                        top: tooltipAlignment.y === 'top' ? tooltipPosition.y + 40 : 'auto',
                                        bottom: tooltipAlignment.y === 'bottom' ? 40 : 'auto',
                                        left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                        right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                                    }}
                                    className="absolute custom_tool_tip z-20 text-xl font-semibold border border-black hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white rounded-lg shadow-lg"
                                >
                                    Visual Concept
                                </div>
                            </Link>
                        </div>
                        <div className="col-span-1 border border-black relative cursor-pointer group rounded-[40px] overflow-hidden port_img"
                            onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}>
                            <Link to={`/portfolio-slug/11`}>
                                <img
                                    src="/image/portfoilo/img/port-list3-img9.jpg"
                                    alt="Error1"
                                    className="h-full w-full object-cover img_hover_effect"
                                />
                                <img
                                    src="/image/portfoilo/img/port-list3-img9.jpg"
                                    alt="Error1"
                                    className="h-full w-full object-cover img_hover_effect"
                                />
                                <div
                                    style={{
                                        top: tooltipAlignment.y === 'top' ? tooltipPosition.y : 'auto',
                                        bottom: tooltipAlignment.y === 'bottom' ? 0 : 'auto',
                                        left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                        right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                                    }}
                                    className="absolute z-20 border border-black uppercase px-3 hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white text-sm rounded-lg shadow-lg"
                                >
                                    Art
                                </div>
                                <div
                                    style={{
                                        top: tooltipAlignment.y === 'top' ? tooltipPosition.y + 40 : 'auto',
                                        bottom: tooltipAlignment.y === 'bottom' ? 40 : 'auto',
                                        left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                        right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                                    }}
                                    className="absolute custom_tool_tip z-20 text-xl font-semibold border border-black hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white rounded-lg shadow-lg"
                                >
                                    Visual Concept
                                </div>
                            </Link>
                        </div>
                        <div className="col-span-1 border border-black relative cursor-pointer group rounded-[40px] overflow-hidden port_img"
                            onMouseMove={(e) => handleMouseMove(e, e.currentTarget)}>
                            <Link to={`/portfolio-slug/12`}>
                                <img
                                    src="/image/portfoilo/img/port-list2-img1.jpg"
                                    alt="Error1"
                                    className="h-full w-full object-cover img_hover_effect"
                                />
                                <img
                                    src="/image/portfoilo/img/port-list2-img1.jpg"
                                    alt="Error1"
                                    className="h-full w-full object-cover img_hover_effect"
                                />
                                <div
                                    style={{
                                        top: tooltipAlignment.y === 'top' ? tooltipPosition.y : 'auto',
                                        bottom: tooltipAlignment.y === 'bottom' ? 0 : 'auto',
                                        left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                        right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                                    }}
                                    className="absolute z-20 border border-black uppercase px-3 hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white text-sm rounded-lg shadow-lg"
                                >
                                    Art
                                </div>
                                <div
                                    style={{
                                        top: tooltipAlignment.y === 'top' ? tooltipPosition.y + 40 : 'auto',
                                        bottom: tooltipAlignment.y === 'bottom' ? 40 : 'auto',
                                        left: tooltipAlignment.x === 'left' ? tooltipPosition.x : 'auto',
                                        right: tooltipAlignment.x === 'right' ? '50px' : 'auto',
                                    }}
                                    className="absolute custom_tool_tip z-20 text-xl font-semibold border border-black hidden group-hover:block p-2 w-fit whitespace-nowrap bg-white rounded-lg shadow-lg"
                                >
                                    Visual Concept
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}