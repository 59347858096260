import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdFormatQuote } from 'react-icons/md';

export const Testimonials = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='relative pb-20'>
            <Slider {...settings}>
                <div className={`relative mb-[40px] `}>
                    <img src={`/image/home/persion1.jpg`} className={`rounded-full overflow-hidden border border-black h-[200px] w-[200px]`} alt={`category-1`} />
                    <div className={`border border-black w-[80%] ml-auto -mt-14 relative bg-white flex flex-col justify-start space-y-4 p-4 sm:p-8 rounded-2xl `}>
                        <h3 className={`font-semibold text-xl`}>Yogita Rajput</h3>
                        <div className={`text-black/90 text-sm sm:text-base`}><p>Working with this team on our brand identity was a game-changer. They captured our vision perfectly and created a brand that truly stands out. We’ve received so much positive feedback from our customers!</p></div>
                    </div>
                    <div className="absolute -bottom-5 -right-0 border border-black bg-[#cbdec6] rounded-xl p-3">
                        <MdFormatQuote className='text-3xl' />
                    </div>
                </div>
                <div className={`relative mb-[40px] pr-8 max-sm:right-6`}>
                    <img src={`/image/home/persion2.jpg`} className={`rounded-full overflow-hidden border border-black h-[200px] w-[200px]`} alt={`category-1`} />
                    <div className={`border border-black w-[80%] ml-auto -mt-14 relative bg-white flex flex-col justify-start space-y-4 p-4 sm:p-8 rounded-2xl `}>
                        <h3 className={`font-semibold text-xl`}>Soham Dhanani</h3>
                        <div className={`text-black/90 text-sm sm:text-base`}><p>The visual designs they created for us were stunning and beyond our expectations. Every piece they delivered felt like it was crafted specifically to resonate with our audience. We couldn’t be happier!</p></div>
                    </div>
                    <div className="absolute -bottom-5 -right-0 border border-black bg-[#cbdec6] rounded-xl p-3">
                        <MdFormatQuote className='text-3xl' />
                    </div>
                </div>
                <div className={`relative mb-[40px] pr-8 max-sm:right-6`}>
                    <img src={`/image/home/persion3.jpg`} className={`rounded-full overflow-hidden border border-black h-[200px] w-[200px]`} alt={`category-1`} />
                    <div className={`border border-black w-[80%] ml-auto -mt-14 relative bg-white flex flex-col justify-start space-y-4 p-4 sm:p-8 rounded-2xl `}>
                        <h3 className={`font-semibold text-xl`}>Frisian Anne</h3>
                        <div className={`text-black/90 text-sm sm:text-base`}><p>Their lead generation strategies brought in exactly the kind of clients we were looking for. Our sales team is thrilled with the quality of leads coming in. Highly recommend for anyone serious about growth!</p></div>
                    </div>
                    <div className="absolute -bottom-5 -right-0 border border-black bg-[#cbdec6] rounded-xl p-3">
                        <MdFormatQuote className='text-3xl' />
                    </div>
                </div>
                <div className={`relative mb-[40px] pr-8 max-sm:right-6`}>
                    <img src={`/image/home/persion4.jpg`} className={`rounded-full overflow-hidden border border-black h-[200px] w-[200px]`} alt={`category-1`} />
                    <div className={`border border-black w-[80%] ml-auto -mt-14 relative bg-white flex flex-col justify-start space-y-4 p-4 sm:p-8 rounded-2xl `}>
                        <h3 className={`font-semibold text-xl`}>María José</h3>
                        <div className={`text-black/90 text-sm sm:text-base`}><p>Since partnering with them for our e-commerce marketing, our online sales have skyrocketed! Their targeted approach and attention to data made a real difference. We’re so grateful for the results!</p></div>
                    </div>
                    <div className="absolute -bottom-5 -right-0 border border-black bg-[#cbdec6] rounded-xl p-3">
                        <MdFormatQuote className='text-3xl' />
                    </div>
                </div>
            </Slider>
            <div className="swiper-pagination-custom mt-4"></div>
        </div>
    );
};
