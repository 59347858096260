import React from 'react'
import Sidebar from '../Sidebar'
import CommentForm from './CommentForm'
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa'
import CommentSection from './Commnet'
import ProfileCard from './ProfileCard'
import CommonBanner from '../../../component/common/CommonBanner'

export default function Post() {
    return (
        <>
            <CommonBanner />
            <div className='space-y-5 mt-8'>
                <div className={`grid grid-cols-12 gap-y-10 custom_container`}>
                    {/* <div className='col-span-12 md:col-span-4 lg:col-span-3 max-lg:order-1'>
                        <Sidebar />
                    </div> */}
                    <div className='col-span-12 lg:col-span-1 hidden lg:block'></div>
                    <div className='col-span-12 lg:col-span-8'>
                        <div className="grid">
                            <div className="space-y-4">
                                <div className="relative h-[500px] rounded-2xl overflow-hidden">
                                    <img src={`/image/home/blog1.jpg`} alt={`blog`} className="h-full object-cover w-full" />
                                    <div className="border border-black rounded-xl bg-[#ffc021] absolute top-6 left-6 p-2 w-min text-center">
                                        <h4 className="text-2xl font-semibold">02 DEC</h4>
                                    </div>
                                </div>
                                <div className="space-y-2 py-4 lg:py-8">
                                    <p className="uppercase">Influencers</p>
                                    <h2 className="capitalize text-2xl sm:text-3xl lg:text-5xl font-semibold">How much do Influencers charge in India 2025?</h2>
                                    <p>
                                        The exciting field of influence marketing in India has experienced tremendous growth especially as brands seek to engage with the target customer on social media. Before we get to know the year 2025, it is crucial to understand the price of engagement with influencers and the ways of working with those who can help in marketing initiatives. It also depends on the division they are split into, including nano, micro, macro and mega influencers, number of followers, level of engagement, and platform of choice as well as the nature of content generated.
                                    </p>
                                    <p>
                                        In this guide, we will explain the further division of influencer charges in India by categories and all social media platforms, discuss factors that define the pricing, and demonstrate how Codeyes can optimize brand’s influencer marketing.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* <div className={`grid md:grid-cols-12 gap-y-5 gap-x-12`}>
                            <div className='col-span-6'>
                                <div className="h-[400px] rounded-xl overflow-hidden">
                                    <img src={`/image/home/blog3.jpg`} alt={`blog`} className="h-full object-cover w-full" />
                                </div>
                            </div>
                            <div className='col-span-6'>
                                <div className="h-[400px] rounded-xl overflow-hidden">
                                    <img src={`/image/home/blog2.jpg`} alt={`blog`} className="h-full object-cover w-full" />
                                </div>
                            </div>
                        </div> */}
                        <div className="space-y-2 border-b border-black">
                            <h2 className="capitalize text-2xl sm:text-3xl lg:text-5xl font-semibold">Factors Affecting Influencer Pricing:</h2>
                            <ul className="list-disc pl-6">
                                <li><strong>Follower Count:</strong> Fees increase with audience size, though engagement rates often hold more value than follower numbers.</li>
                                <li><strong>Engagement Rate:</strong> Influencers with higher audience interaction charge more, reflecting their ability to deliver better responsiveness.</li>
                                <li><strong>Content-Type:</strong> Videos, live streams, and carousel posts typically cost more than static images or text.</li>
                                <li><strong>Industry Niche:</strong> Influencers in specialized fields like technology or finance may charge a premium due to their niche expertise.</li>
                                <li><strong>Platform Used:</strong> Pricing varies by platform, with some offering higher engagement rates than others.</li>
                                <li><strong>Campaign Scope:</strong> The complexity and duration of a campaign significantly influence the pricing.</li>
                            </ul>

                            <h2 className="pt-4 lg:pt-8 capitalize text-2xl sm:text-3xl lg:text-5xl font-semibold">Average Pricing Breakdown by Influencer Category</h2>
                            <ul className="list-disc pl-6">
                                <li><strong>Nano Influencers (1K – 10K followers):</strong> ₹1,000 – ₹5,000 per post. Ideal for niche marketing.</li>
                                <li><strong>Medium Influencers (10K – 100K followers):</strong> ₹5,000 – ₹20,000 per post. Perfect for moderate coverage at a reasonable cost.</li>
                                <li><strong>Large-Scale Influencers (100K – 1M followers):</strong> ₹20,000 – ₹2,00,000 per post. Best for mid- to large-scale campaigns.</li>
                                <li><strong>Mega Influencers & Celebrities (1M+ followers):</strong> ₹2,00,000 – ₹50,00,000+ per post. Suited for extensive promotional campaigns.</li>
                            </ul>

                            <h2 className="pt-4 lg:pt-8 capitalize text-2xl sm:text-3xl lg:text-5xl font-semibold">Average Pricing Breakdown by Social Media Platform</h2>
                            <ul className="list-disc pl-6">
                                <li><strong>Instagram:</strong> Stories ₹1,000 – ₹2,00,000 | Posts ₹5,000 – ₹5,00,000 | Reels ₹10,000 – ₹8,00,000</li>
                                <li><strong>Facebook:</strong> Posts ₹2,000 – ₹1,50,000 | Videos ₹5,000 – ₹3,00,000 | Live Streams ₹10,000 – ₹5,00,000</li>
                                <li><strong>YouTube:</strong> Integration ₹10,000 – ₹5,00,000+ | Dedicated Videos ₹50,000 – ₹10,00,000+</li>
                                <li><strong>Twitter (X):</strong> Tweets ₹1,000 – ₹50,000 | Campaigns ₹10,000 – ₹2,00,000</li>
                                <li><strong>LinkedIn:</strong> Posts ₹10,000 – ₹50,000 | Articles ₹20,000 – ₹1,00,000</li>
                            </ul>

                            <h2 className="pt-4 lg:pt-8 text-2xl sm:text-3xl lg:text-5xl font-semibold">How Codeyes Media Supports Influencer Marketing:</h2>
                            <ul className="list-disc pl-6">
                                <li><strong>Comprehensive Influencer Database:</strong> Engage with influencers across all categories and platforms.</li>
                                <li><strong>Data-Driven Matchmaking:</strong> We analyze your target audience and brand goals to connect you with the right influencer.</li>
                                <li><strong>Campaign Management:</strong> From planning to execution, we handle every aspect of your influencer campaigns.</li>
                                <li><strong>Performance Tracking:</strong> Immediate results analysis ensures better ROI and informs future strategies.</li>
                                <li><strong>Cross-Platform Expertise:</strong> Whether Instagram, YouTube, or LinkedIn, we make your brand recognizable.</li>
                            </ul>

                            <p className="py-4 lg:py-8 ">Unlike other companies that sell influencers, at Codeyes, you are purchasing efficient and effective campaigns with guaranteed outcomes.</p>

                            <h2 className="text-2xl sm:text-3xl lg:text-5xl font-semibold">Conclusion</h2>


                            <p>Influencer marketing remains one of the most promising trends in digital marketing as it is in 2025 allowing brands to communicate directly with the target markets. Understanding pricing, costs and collaborating with a strategic partner such as Codeyes allows brands to get the best results from influencer marketing.

                            </p>
                            <p className="pb-4 lg:pb-8 ">Get in touch with us today and find out how Codeyes can assist to enhance your influencer marketing. Let’s build something great together.</p>

                        </div>

                        <div className='flex justify-between flex-wrap gap-x-4 gap-4 mt-6'>
                            <div className='flex gap-3'>
                                <p className='border border-black rounded-[50px] px-4 w-fit py-1'>Digital Marketing</p>
                                <p className='border border-black rounded-[50px] px-4 w-fit py-1'>Influencer Marketing</p>
                            </div>
                            {/* <div className="custom_tool_tip z-20 text-xl px-6 font-semibold relative  border border-black p-2 w-fit whitespace-nowrap bg-white rounded-lg shadow-lg" >
                                <div className='flex gap-4'>
                                    <div className={`hover:text-[#ffc021] text-sm cursor-pointer`}><FaLinkedin /></div>
                                    <div className={`hover:text-[#ffc021] text-sm cursor-pointer `}><FaFacebook /></div>
                                    <div className={`hover:text-[#ffc021] text-sm cursor-pointer `}><FaTwitter /></div>
                                    <div className={`hover:text-[#ffc021] text-sm cursor-pointer`}><FaInstagram /></div>
                                </div>
                            </div> */}
                        </div>
                        {/* <ProfileCard
                            name="Steven Zissou"
                            role="MARKETING"
                            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua."
                            imageUrl="https://i.pravatar.cc/150?img=32"
                        />
                        <CommentSection />
                        <CommentForm /> */}
                    </div>
                </div>
            </div>
        </>

    )
}
