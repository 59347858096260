import React from 'react'
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaWhatsapp } from 'react-icons/fa'
import { FaArrowRightLong } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';

export default function Footer() {


    return (
        <>
            <ScrollToTop />
            {/* <div style={{ background: `url(/image/home/bacground-img.png)` }}> */}
            <div className={`border custom_container !py-0 border-black bg-[#cbdec6] overflow-hidden rounded-[70px] mb-10`}>
                <div className={`md:px-[70px] !bg-[#fbfaf5] grid md:grid-cols-6 border-b border-black`}>
                    <div className={`col-span-3 py-8 max-md:grid max-md:justify-center`}>
                        <img src="/image/logo.png" alt="Error" className='w-[140px]' />
                    </div>
                    <div className={`col-span-3`}>
                        <div className={`flex w-full h-full md:ml-[70px] max-md:border-t border-black`}>
                            <Link to="https://in.linkedin.com/company/codeyes-media/" className={`border-r hover:text-[#ffc021] max-md:py-6 md:border-l border-black w-full h-full flex items-center justify-center text-3xl`}><FaLinkedin /></Link>
                            <Link to="https://www.facebook.com/codeyesmedia/" className={`border-r hover:text-[#ffc021] max-md:py-6 border-black w-full h-full flex items-center justify-center text-3xl`}><FaFacebook /></Link>
                            <Link to="https://api.whatsapp.com/send?phone=88497239870" className={`border-r hover:text-[#ffc021] max-md:py-6 border-black w-full h-full flex items-center justify-center text-3xl`}><FaWhatsapp /></Link>
                            <Link to="https://www.instagram.com/codeyesmedia/" className={` w-full  hover:text-[#ffc021] max-md:py-6 h-full flex items-center justify-center text-3xl`}><FaInstagram /></Link>
                        </div>
                    </div>
                </div>
                <div className={`my-20 md:my-[170px] mx-[6%] md:mx-[70px]`}>
                    <div className={`grid sm:grid-cols-2 lg:grid-cols-4 gap-y-12 gap-x-10`}>
                        <div className={`col-span-1 space-y-3`}>
                            <h5 className='text-2xl font-semibold'>Hello, Partners! :)</h5>
                            <p>Our expert marketing team creates strategies that drive results for your business.</p>
                        </div>
                        <div className={`col-span-1 space-y-3`}>
                            <h5 className='text-2xl font-semibold'>Contact Us</h5>
                            <div className="space-y-1">
                                <p>213, Skyview Business Horizon, nr. Shyamdham Temple, Sarthana Jakat Naka, Surat, Gujarat 395006</p>
                                <p>info@codeyesmedia.com</p>
                                <p>+91 8849723987</p>
                            </div>
                        </div>
                        <div className={`col-span-1 space-y-3`}>
                            <h5 className='text-2xl font-semibold'>About Us</h5>
                            <div className={`space-y-2`}>
                                <p><Link to="/">Home</Link></p>
                                <p><Link to="/about">About</Link></p>
                                {/* <p><Link to="/portfolio">Portfolio</Link></p> */}
                                {/* <p><Link to="/faq">Faqs</Link></p> */}
                                <p><Link to="/growth-advertising">Growth Advertising</Link></p>
                                <p><Link to="/creative-marketing">Creative Marketing</Link></p>
                                {/* <p><Link to="/our-client">Our Client</Link></p>
                                <p><Link to="/our-team">Our Team</Link></p>
                                <p><Link to="/career">Career</Link></p>
                                <p><Link to="/blog">Blog</Link></p>
                                <p><Link to="/privacy-policy">Privacy Policy</Link></p>
                                <p><Link to="/terms-of-use">Terms of use</Link></p> */}
                                <p><Link to="/contact">Contact</Link></p>                                
                            </div>
                        </div>
                        <div className={`col-span-1 space-y-3 mb-4`}>
                            <h5 className='text-2xl font-semibold'>Contact Us</h5>
                            <div className={`space-y-2`}>
                                <p>Want To Work With Us?</p>
                                <div className={`border hidden md:block border-black rounded-full overflow-hidden relative`}>
                                    <input type="text" placeholder='Email...' className='bg-white w-full border-0 outline-0 h-full p-6' />
                                    <button className='border border-r-0 border-black rounded-full bg-[#ffc021] p-2 absolute right-0 h-full w-[65px]'>
                                        <FaArrowRightLong className='text-2xl m-auto' />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`border block md:hidden border-black rounded-full overflow-hidden relative`}>
                        <input type="text" placeholder='Email...' className='bg-white w-full border-0 outline-0 h-full p-6' />
                        <button className='border border-r-0 border-black rounded-full bg-[#ffc021] p-2 absolute right-0 h-full w-[65px]'>
                            <FaArrowRightLong className='text-2xl m-auto' />
                        </button>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    )
}
