import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdFormatQuote } from 'react-icons/md';

export const Testimonials = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='relative pb-20'>
            <Slider {...settings}>
                {/* Testimonial 1 */}
                <div className={`relative mb-[40px]`}>
                    <img src={`/image/home/persion4.png`} className={`rounded-full overflow-hidden border border-black h-[200px] w-[200px]`} alt={`category-1`} />
                    <div className={`border border-black w-[80%] ml-auto -mt-14 relative bg-white flex flex-col justify-start space-y-4 p-4 sm:p-8 rounded-2xl`}>
                        <h3 className={`font-semibold text-xl`}>Jason Lee, Content Manager</h3>
                        <div className={`text-black/90 text-sm sm:text-base`}>
                            <p>Codeyes Media has been a key partner in growing our YouTube channel. They helped create a tailored strategy that has increased our subscribers and engagement dramatically.</p>
                        </div>
                    </div>
                    <div className="absolute -bottom-5 -right-0 border border-black bg-[#cbdec6] rounded-xl p-3">
                        <MdFormatQuote className='text-3xl' />
                    </div>
                </div>

                {/* Testimonial 2 */}
                <div className={`relative mb-[40px]`}>
                    <img src={`/image/home/persion4.png`} className={`rounded-full overflow-hidden border border-black h-[200px] w-[200px]`} alt={`category-2`} />
                    <div className={`border border-black w-[80%] ml-auto -mt-14 relative bg-white flex flex-col justify-start space-y-4 p-4 sm:p-8 rounded-2xl`}>
                        <h3 className={`font-semibold text-xl`}>Emily Johnson, Video Marketing Specialist</h3>
                        <div className={`text-black/90 text-sm sm:text-base`}>
                            <p>The YouTube marketing strategy Codeyes Media developed for us was exactly what we needed. Their approach has increased our channel views and helped us grow a loyal audience.</p>
                        </div>
                    </div>
                    <div className="absolute -bottom-5 -right-0 border border-black bg-[#cbdec6] rounded-xl p-3">
                        <MdFormatQuote className='text-3xl' />
                    </div>
                </div>

                {/* Testimonial 3 */}
                <div className={`relative mb-[40px]`}>
                    <img src={`/image/home/persion4.png`} className={`rounded-full overflow-hidden border border-black h-[200px] w-[200px]`} alt={`category-3`} />
                    <div className={`border border-black w-[80%] ml-auto -mt-14 relative bg-white flex flex-col justify-start space-y-4 p-4 sm:p-8 rounded-2xl`}>
                        <h3 className={`font-semibold text-xl`}>David Chen, Marketing Director</h3>
                        <div className={`text-black/90 text-sm sm:text-base`}>
                            <p>Codeyes Media took our YouTube presence to the next level. Their content strategy and optimization techniques have resulted in more views, higher subscriber growth, and stronger audience interaction.</p>
                        </div>
                    </div>
                    <div className="absolute -bottom-5 -right-0 border border-black bg-[#cbdec6] rounded-xl p-3">
                        <MdFormatQuote className='text-3xl' />
                    </div>
                </div>
            </Slider>
            <div className="swiper-pagination-custom mt-4"></div>
        </div>

    );
};
