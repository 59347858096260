import React, { useState } from 'react';
import { GoDotFill } from 'react-icons/go';
import CommonBanner from '../../component/common/CommonBanner';
import AnimatedReadMore from '../../component/common/AnimatedReadMore';
import News from '../../component/home/News';
import { Link } from 'react-router-dom';

const blogPosts = [
    {
        id: 1,
        image: '/image/home/blog1.jpg',
        date: '02 DEC',
        category: 'Digital Marketing',
        title: 'How much do Influencers charge in India 2025?',
    }
];

export default function Blog() {
    const [visiblePosts, setVisiblePosts] = useState(6);

    const loadMorePosts = () => {
        setVisiblePosts((prevVisiblePosts) => prevVisiblePosts + 3);
    };

    return (
        <>
            <CommonBanner />
            <div className='custom_container mb-14'>
                <div className="my-18">
                    <h2 className="uppercase text-8xl font-extrabold mt-6 mb-12 whitespace-break-spaces break-words">
                        read some
                        <span style={{ WebkitTextStroke: '1px black', color: 'transparent' }}>news</span>
                    </h2>
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-12 mt-4">
                        {blogPosts.slice(0, visiblePosts).map((post) => (
                            <div key={post.id} className="col-span-1 border border-black rounded-[50px] overflow-hidden space-y-4 news_card">
                                <div className="relative h-[300px] border-b border-black rounded-[50px] overflow-hidden">
                                    <img src={post.image} alt={`blog${post.id}`} className="h-full object-cover w-full" />
                                    <div className="border border-black rounded-xl bg-[#ffc021] absolute top-6 right-6 p-2 w-min text-center">
                                        <h4 className="text-2xl font-semibold">{post.date}</h4>
                                    </div>
                                </div>
                                <div className='p-6 pt-0'>
                                    <div className="space-y-2 p-4 lg:p-8 !pb-4">
                                        <p className="uppercase text-sm">({post.category})</p>
                                        <h2 className="capitalize text-xl lg:text-2xl font-semibold">{post.title}</h2>
                                        <Link to={`/post/${post.id}`} >
                                            <AnimatedReadMore />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {visiblePosts < blogPosts.length && (
                        <div className='my-6 sm:my-12'>
                            <button
                                onClick={loadMorePosts}
                                className="relative border border-black rounded-full font-semibold px-3 group hover:px-0 py-2 duration-100 ease-in-out cursor-pointer uppercase w-[160px] m-auto text-lg overflow-hidden flex items-center mt-10"
                            >
                                <span className="absolute w-0 h-0 transition-all duration-500 ease-out bg-[#ffc021] rounded-full group-hover:w-full group-hover:h-56"></span>
                                <span className="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent"></span>
                                <div className="sliding-text-wrapper flex items-center whitespace-nowrap relative z-10">
                                    <GoDotFill className="text-[#ffc021]" />
                                    <span className="sliding-text hidden group-hover:block">Load More</span>
                                    <GoDotFill className="text-[#ffc021] hidden group-hover:block" />
                                    <span className="sliding-text hidden group-hover:block">Load More</span>
                                    <GoDotFill className="text-[#ffc021] hidden group-hover:block" />
                                    <span className="sliding-text">Load More</span>
                                </div>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {/* <div className='mb-24'>
                <div className='border border-black rounded-[50px] bg-[#cbdec6] p-6 md:p-14'>
                    <h2 className='text-[40px] sm:text-[54px] sm:leading-[60px] font-semibold max-sm:mt-8 mb-12'>Read Our Blog</h2>
                    {blogPosts.slice(0, 3).map((post) => (
                        <div
                            key={post.id}
                            className='grid grid-cols-12 gap-5 md:gap-10 md:h-[350px] lg:h-[400px] border border-black rounded-[50px] overflow-hidden space-y-4 news_card md:relative bg-white'
                        >
                            <div className='col-span-12 max-md:h-[260px] md:col-span-4 border-b md:border-r max-md:relative border-black rounded-[50px] overflow-hidden'>
                                <img src={post.image} alt={`blog${post.id}`} className='h-full object-cover w-full' />
                                <div className='border border-black rounded-xl bg-[#ffc021] absolute top-10 right-10 p-3 md:py-4 md:px-6 w-min text-center'>
                                    <h4 className='font-semibold'>
                                        <span className='text-2xl md:text-3xl'>{post.date.split(' ')[0]}</span>
                                        <span className='text-2xl'> {post.date.split(' ')[1]}</span>
                                    </h4>
                                </div>
                            </div>
                            <div className='col-span-12 md:col-span-5 lg:col-span-6 p-6 pt-0'>
                                <div className='space-y-2 h-full p-4 lg:p-8'>
                                    <div className='grid h-full justify-between gap-5'>
                                        <div>
                                            <p className='uppercase text-sm'>({post.category})</p>
                                            <h2 className='capitalize text-4xl font-semibold'>{post.title}</h2>
                                        </div>
                                        <Link to={`/post/${post.id}`} >
                                            <AnimatedReadMore />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
            <div className='mb-24'>
                <News />
            </div> */}
        </>
    );
}























// import React from 'react'
// import { GoDotFill } from 'react-icons/go'
// import { Link } from 'react-router-dom';
// import Banner from '../../component/home/Banner';
// import Sidebar from './Sidebar';
// import CommonBanner from '../../component/common/CommonBanner';

// const blogPosts = [
//     {
//         id: 1,
//         image: '/image/home/blog1.jpg',
//         date: '13 MAR',
//         category: 'creative',
//         title: 'Got Your Eye On This Season’s Trends',
//         content: 'Voluptatem ea rerum nisi. Ullam debitis optio. Quae odio quasi repellat sit fugiat dolor manet. Officia et doloum. Eos non itaque ut libero dolorum hic voluptas repudiandae. Fugiat bisun quos et odit vel optio. Autm neque volu ptatum. Et et ducimus ut est aut. Voluptatem ea rerum nisi. Turpis nunc eget lorem dolor sed viverra ipsum. Non consectetur a erat nam at bibendum.'
//     },
//     {
//         id: 2,
//         image: '/image/home/blog2.jpg',
//         date: '13 MAR',
//         category: 'creative',
//         title: 'Got Your Eye On This Season’s Trends',
//         content: 'Voluptatem ea rerum nisi. Ullam debitis optio. Quae odio quasi repellat sit fugiat dolor manet. Officia et doloum. Eos non itaque ut libero dolorum hic voluptas repudiandae. Fugiat bisun quos et odit vel optio. Autm neque volu ptatum. Et et ducimus ut est aut. Voluptatem ea rerum nisi. Turpis nunc eget lorem dolor sed viverra ipsum. Non consectetur a erat nam at bibendum.'
//     },
//     {
//         id: 3,
//         image: '/image/home/blog3.jpg',
//         date: '13 MAR',
//         category: 'creative',
//         title: 'Got Your Eye On This Season’s Trends',
//         content: 'Voluptatem ea rerum nisi. Ullam debitis optio. Quae odio quasi repellat sit fugiat dolor manet. Officia et doloum. Eos non itaque ut libero dolorum hic voluptas repudiandae. Fugiat bisun quos et odit vel optio. Autm neque volu ptatum. Et et ducimus ut est aut. Voluptatem ea rerum nisi. Turpis nunc eget lorem dolor sed viverra ipsum. Non consectetur a erat nam at bibendum.'
//     },
//     {
//         id: 4,
//         image: '/image/home/blog1.jpg',
//         date: '13 MAR',
//         category: 'creative',
//         title: 'Got Your Eye On This Season’s Trends',
//         content: 'Voluptatem ea rerum nisi. Ullam debitis optio. Quae odio quasi repellat sit fugiat dolor manet. Officia et doloum. Eos non itaque ut libero dolorum hic voluptas repudiandae. Fugiat bisun quos et odit vel optio. Autm neque volu ptatum. Et et ducimus ut est aut. Voluptatem ea rerum nisi. Turpis nunc eget lorem dolor sed viverra ipsum. Non consectetur a erat nam at bibendum.'
//     },
//     {
//         id: 5,
//         image: '/image/home/blog2.jpg',
//         date: '13 MAR',
//         category: 'creative',
//         title: 'Got Your Eye On This Season’s Trends',
//         content: 'Voluptatem ea rerum nisi. Ullam debitis optio. Quae odio quasi repellat sit fugiat dolor manet. Officia et doloum. Eos non itaque ut libero dolorum hic voluptas repudiandae. Fugiat bisun quos et odit vel optio. Autm neque volu ptatum. Et et ducimus ut est aut. Voluptatem ea rerum nisi. Turpis nunc eget lorem dolor sed viverra ipsum. Non consectetur a erat nam at bibendum.'
//     },
//     {
//         id: 6,
//         image: '/image/home/blog3.jpg',
//         date: '13 MAR',
//         category: 'creative',
//         title: 'Got Your Eye On This Season’s Trends',
//         content: 'Voluptatem ea rerum nisi. Ullam debitis optio. Quae odio quasi repellat sit fugiat dolor manet. Officia et doloum. Eos non itaque ut libero dolorum hic voluptas repudiandae. Fugiat bisun quos et odit vel optio. Autm neque volu ptatum. Et et ducimus ut est aut. Voluptatem ea rerum nisi. Turpis nunc eget lorem dolor sed viverra ipsum. Non consectetur a erat nam at bibendum.'
//     },
//     {
//         id: 7,
//         image: '/image/home/blog1.jpg',
//         date: '13 MAR',
//         category: 'creative',
//         title: 'Got Your Eye On This Season’s Trends',
//         content: 'Voluptatem ea rerum nisi. Ullam debitis optio. Quae odio quasi repellat sit fugiat dolor manet. Officia et doloum. Eos non itaque ut libero dolorum hic voluptas repudiandae. Fugiat bisun quos et odit vel optio. Autm neque volu ptatum. Et et ducimus ut est aut. Voluptatem ea rerum nisi. Turpis nunc eget lorem dolor sed viverra ipsum. Non consectetur a erat nam at bibendum.'
//     },
//     {
//         id: 8,
//         image: '/image/home/blog2.jpg',
//         date: '13 MAR',
//         category: 'creative',
//         title: 'Got Your Eye On This Season’s Trends',
//         content: 'Voluptatem ea rerum nisi. Ullam debitis optio. Quae odio quasi repellat sit fugiat dolor manet. Officia et doloum. Eos non itaque ut libero dolorum hic voluptas repudiandae. Fugiat bisun quos et odit vel optio. Autm neque volu ptatum. Et et ducimus ut est aut. Voluptatem ea rerum nisi. Turpis nunc eget lorem dolor sed viverra ipsum. Non consectetur a erat nam at bibendum.'
//     },
//     {
//         id: 9,
//         image: '/image/home/blog3.jpg',
//         date: '13 MAR',
//         category: 'creative',
//         title: 'Got Your Eye On This Season’s Trends',
//         content: 'Voluptatem ea rerum nisi. Ullam debitis optio. Quae odio quasi repellat sit fugiat dolor manet. Officia et doloum. Eos non itaque ut libero dolorum hic voluptas repudiandae. Fugiat bisun quos et odit vel optio. Autm neque volu ptatum. Et et ducimus ut est aut. Voluptatem ea rerum nisi. Turpis nunc eget lorem dolor sed viverra ipsum. Non consectetur a erat nam at bibendum.'
//     },
//     {
//         id: 10,
//         image: '/image/home/blog1.jpg',
//         date: '13 MAR',
//         category: 'creative',
//         title: 'Got Your Eye On This Season’s Trends',
//         content: 'Voluptatem ea rerum nisi. Ullam debitis optio. Quae odio quasi repellat sit fugiat dolor manet. Officia et doloum. Eos non itaque ut libero dolorum hic voluptas repudiandae. Fugiat bisun quos et odit vel optio. Autm neque volu ptatum. Et et ducimus ut est aut. Voluptatem ea rerum nisi. Turpis nunc eget lorem dolor sed viverra ipsum. Non consectetur a erat nam at bibendum.'
//     },
//     {
//         id: 11,
//         image: '/image/home/blog2.jpg',
//         date: '13 MAR',
//         category: 'creative',
//         title: 'Got Your Eye On This Season’s Trends',
//         content: 'Voluptatem ea rerum nisi. Ullam debitis optio. Quae odio quasi repellat sit fugiat dolor manet. Officia et doloum. Eos non itaque ut libero dolorum hic voluptas repudiandae. Fugiat bisun quos et odit vel optio. Autm neque volu ptatum. Et et ducimus ut est aut. Voluptatem ea rerum nisi. Turpis nunc eget lorem dolor sed viverra ipsum. Non consectetur a erat nam at bibendum.'
//     },
//     {
//         id: 12,
//         image: '/image/home/blog3.jpg',
//         date: '13 MAR',
//         category: 'creative',
//         title: 'Got Your Eye On This Season’s Trends',
//         content: 'Voluptatem ea rerum nisi. Ullam debitis optio. Quae odio quasi repellat sit fugiat dolor manet. Officia et doloum. Eos non itaque ut libero dolorum hic voluptas repudiandae. Fugiat bisun quos et odit vel optio. Autm neque volu ptatum. Et et ducimus ut est aut. Voluptatem ea rerum nisi. Turpis nunc eget lorem dolor sed viverra ipsum. Non consectetur a erat nam at bibendum.'
//     },
// ];

// export default function Blog() {
//     return (
//         <>
//             <CommonBanner />
//             <div className="custom_container my-24">
//                 {/* <h2 className="uppercase text-6xl md:text-[90px] md:leading-[100px] font-extrabold">
//                     Latest <span style={{ WebkitTextStroke: '1px black', color: 'transparent' }}>BLOGS</span>
//                 </h2> */}
//                 <div className={`grid grid-cols-12 gap-y-10 w-[90%] m-auto`}>
//                     <div className='col-span-12 md:col-span-4 lg:col-span-3 max-lg:order-1'>
//                         <Sidebar />
//                     </div>
//                     <div className='col-span-12 lg:col-span-1 hidden lg:block'></div>

//                     <div className='col-span-12 lg:col-span-8'>
//                         <div className="grid">
//                             {blogPosts.map((post) => (
//                                 <div key={post.id} className="space-y-4">
//                                     <Link to={`/post/${post.id}`} >
//                                         <div className="relative h-[500px] rounded-2xl overflow-hidden">
//                                             <img src={post.image} alt={`blog${post.id}`} className="h-full object-cover w-full" />
//                                             <div className="border border-black rounded-xl bg-[#ffc021] absolute top-6 left-6 p-2 w-min text-center">
//                                                 <h4 className="text-2xl font-semibold">{post.date}</h4>
//                                             </div>
//                                         </div>
//                                         <div className="space-y-2 py-4 lg:py-8">
//                                             <p className="uppercase ">({post.category})</p>
//                                             <h2 className="capitalize text-2xl sm:text-3xl lg:text-5xl font-semibold">{post.title}</h2>
//                                             <p>{post?.content}</p>
//                                             <button className={`relative border border-black rounded-full font-semibold px-3 group hover:px-0 py-2 duration-100 ease-in-out cursor-pointer uppercase w-[160px]  text-lg overflow-hidden flex items-center mt-10`}>
//                                                 <span className="absolute w-0 h-0 transition-all duration-500 ease-out bg-[#ffc021] rounded-full group-hover:w-full group-hover:h-56"></span>
//                                                 <span className="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent"></span>
//                                                 <div className="sliding-text-wrapper flex items-center whitespace-nowrap relative z-10">
//                                                     <GoDotFill className="text-[#ffc021]" />
//                                                     <span className="sliding-text hidden group-hover:block">Read More</span>
//                                                     <GoDotFill className="text-[#ffc021] hidden group-hover:block" />
//                                                     <span className="sliding-text hidden group-hover:block">Read More</span>
//                                                     <GoDotFill className="text-[#ffc021] hidden group-hover:block" />
//                                                     <span className="sliding-text">Read More</span>
//                                                 </div>
//                                             </button>
//                                         </div>
//                                     </Link>
//                                 </div>
//                             ))}
//                         </div>
//                     </div>

//                 </div>
//             </div>
//         </>

//     );
// }
