import React, { useState } from "react";
import { GoDotFill } from "react-icons/go";
import { GoogleMap, Marker, LoadScript, InfoWindow } from '@react-google-maps/api';

const mapContainerStyle = {
    width: '100%',
    height: '700px'
};

const ContactForm = ({ mapCenter, selectedAddress }) => {
    // State for custom dropdown
    const [selectedService, setSelectedService] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);

    // State to handle InfoWindow visibility
    const [infoWindowVisible, setInfoWindowVisible] = useState(false);
    const [infoWindowPosition, setInfoWindowPosition] = useState(mapCenter);

    const services = [
        { value: "branding", label: "Branding" },
        { value: "graphicDesign", label: "Graphic Design" },
        { value: "3dBranding", label: "3D Branding" },
        { value: "productPhotography", label: "Product Photography" },
        { value: "modeling", label: "Modeling" },
        { value: "videoEditing", label: "Video Editing" },
        { value: "motionGraphics", label: "Motion Graphics" },
        { value: "socialMedia", label: "Social Media Management" },
        { value: "influencerMarketing", label: "Influencer Marketing" },
        { value: "ecommerceMarketing", label: "E-commerce Marketing" },
        { value: "facebookAds", label: "Facebook Ads" },
        { value: "ppc", label: "Pay-Per-Click Advertising (PPC)" },
        { value: "youtubeMarketing", label: "YouTube Marketing" },
        { value: "emailMarketing", label: "Email Marketing" },
        { value: "seo", label: "SEO Services" },
        { value: "contentMarketing", label: "Content Marketing" },
        { value: "digitalMarketingStrategy", label: "Digital Marketing Strategy" }
    ];
    
    const handleServiceSelect = (service) => {
        setSelectedService(service.label);
        setDropdownOpen(false);
    };


    const handleMarkerClick = (position) => {
        setInfoWindowPosition(position);
        setInfoWindowVisible(true);
    };

    const handleInfoWindowClose = () => {
        setInfoWindowVisible(false); 
    };


    return (
        <div className="border border-black rounded-[50px] overflow-hidden justify-center">
            <div className="grid lg:grid-cols-2 p-6 bg-[#cbdec6] rounded-lg shadow-md h-full">
                <div className="w-full lg:col-span-1">
                    <LoadScript googleMapsApiKey="AIzaSyBYTVr8YYdf7qm_TrZdTBKV07S-apoYEbs">
                        <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            center={mapCenter}
                            zoom={14}
                        >
                            <Marker
                                position={mapCenter}
                                onClick={() => handleMarkerClick(mapCenter)}
                            />

                            {infoWindowVisible && (
                                <InfoWindow
                                    position={infoWindowPosition}
                                    onCloseClick={handleInfoWindowClose}
                                >
                                    <div className="flex items-center">
                                        <p>{selectedAddress.city}</p>
                                    </div>
                                </InfoWindow>
                            )}
                        </GoogleMap>
                    </LoadScript>
                </div>

                {/* Right Side: Contact Form */}
                <div className="w-full lg:col-span-1 px-0 md:px-8 lg:px-20 py-16">
                    <h2 className="text-5xl md:text-6xl font-semibold text-[#151015] mb-12">Drop Us A Note!</h2>
                    <form className="space-y-8 mt-10">
                        <div>
                            <input
                                type="text"
                                name="name"
                                placeholder="Name*"
                                className="w-full py-4 px-5 border border-black focus:!outline-none rounded-full"
                            />
                        </div>
                        <div>
                            <input
                                type="email"
                                name="email"
                                placeholder="Email*"
                                className="w-full py-4 px-5 border border-black focus:!outline-none rounded-full"
                            />
                        </div>
                        <div className="relative">
                            <div
                                className="w-full py-4 px-5 border border-black bg-white rounded-full cursor-pointer flex justify-between items-center"
                                onClick={() => setDropdownOpen(!dropdownOpen)}
                            >
                                <span className="text-[#727987]">{selectedService || "Select a Service"}</span>
                                <svg
                                    className={`w-4 h-4 transition-transform ${dropdownOpen ? 'rotate-180' : 'rotate-0'}`}
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                </svg>
                            </div>

                            {dropdownOpen && (
                                <ul className="absolute w-[95%] h-[500%] overflow-y-scroll	left-[2.5%] z-[15] bg-white border border-black rounded-3xl overflow-hidden mt-2">
                                    {services.map(service => (
                                        <li
                                            key={service.value}
                                            className="py-2 px-5 hover:bg-gray-100 cursor-pointer"
                                            onClick={() => handleServiceSelect(service)}
                                        >
                                            {service.label}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div>
                            <textarea
                                name="message"
                                placeholder="Message*"
                                rows={4}
                                className="w-full py-4 px-5 border border-black focus:!outline-none rounded-3xl"
                            ></textarea>
                        </div>
                        <div className="flex">
                            <button
                                type="submit"
                                className="relative border border-black rounded-full font-semibold px-3 group hover:px-0 py-2 duration-100 ease-in-out cursor-pointer uppercase w-[200px] m-auto text-lg overflow-hidden flex items-center mt-10"
                            >
                                <span className="absolute w-0 h-0 transition-all duration-500 ease-out bg-[#ffc021] rounded-full group-hover:w-full group-hover:h-56"></span>
                                <span className="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent"></span>
                                <div className="sliding-text-wrapper flex items-center whitespace-nowrap relative z-10">
                                    <GoDotFill className="text-[#ffc021]" />
                                    <span className="sliding-text hidden group-hover:block">Send Message</span>
                                    <GoDotFill className="text-[#ffc021] hidden group-hover:block" />
                                    <span className="sliding-text hidden group-hover:block">Send Message</span>
                                    <GoDotFill className="text-[#ffc021] hidden group-hover:block" />
                                    <span className="sliding-text">Send Message</span>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
